export type Risks = typeof HIGH_RISK | typeof INTERMEDIATE_HIGH_RISK | typeof INTERMEDIATE_LOW_RISK | typeof LOW_RISK | typeof VERY_HIGH_RISK | typeof VERY_LOW_RISK | typeof INTERMEDIATE_RISK | '';
export const HIGH_RISK = 'HIGH_RISK';
export const VERY_HIGH_RISK = 'VERY_HIGH_RISK';
export const LOW_RISK = 'LOW_RISK';
export const VERY_LOW_RISK = 'VERY_LOW_RISK';
export const INTERMEDIATE_RISK = 'INTERMEDIATE_RISK';
export const INTERMEDIATE_LOW_RISK = 'INTERMEDIATE_LOW_RISK';
export const INTERMEDIATE_HIGH_RISK = 'INTERMEDIATE_HIGH_RISK';

export const T1a = 'T1a';
export const T1b = 'T1b';
export const T1c = 'T1c';
export const T2a = 'T2a';
export const T2b = 'T2b';
export const T2c = 'T2c';
export const T3a = 'T3a';
export const T3b = 'T3b';
export const T4 = 'T4';
export const N1 = 'N1';
export const M1 = 'M1';